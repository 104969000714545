<template>
    <div class="mb-3" :class="{ 'is-invalid': hasError }">
        <label class="form-label" v-if="label.length" :for="labelID">{{ $t(label) }}<template v-if="required !== undefined"> *</template>:</label>
        <div class="input-group" :class="{ 'search': search, 'is-invalid': hasError }">
            <span v-if="prepend" class="input-group-text" v-html="prepend"></span>
            <template v-if="multiline">
                <textarea class="form-control" ref="input_field" :class="{ 'is-invalid': hasError }" v-bind="$attrs" :required="required" :id="labelID" v-model="inputValue" @focus="$emit('focus')" @blur="$emit('blur')"></textarea>
            </template>
            <template v-else>
                <input class="form-control" ref="input_field" :class="{ 'is-invalid': hasError }" v-bind="$attrs" :required="required" :id="labelID" v-model="inputValue" @focus="$emit('focus')" @blur="$emit('blur')">
            </template>
            <span v-if="search" class="input-group-text material-symbols-outlined lg" :class="{ 'clickable': !!inputValue }" v-html="(inputValue ? 'close' : 'search')" @click="clearValue()"></span>
        </div>
        <div :class="{ 'invalid-feedback': hasError, 'feedback': !hasError }" v-if="feedback.length">
            <template v-for="(msg, i) in feedback">
                <span :key="i">{{ msg }}<br /></span>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
})
export default class PCInput extends Vue {
    @Prop({ default: '' })
    readonly value: string;

    @Prop({ default: '' })
    readonly label: string;

    @Prop({ default: false })
    readonly hasError: boolean;

    @Prop({ default: () => [] })
    readonly feedback: Array<string>;

    @Prop({ default: false })
    readonly search: boolean;

    @Prop({ default: '' })
    readonly prepend: string;

    @Prop()
    readonly required: string;

    @Prop({ default: false })
    readonly multiline: boolean;

    inputValue = '';
    labelID = '';

    mounted () {
        this.inputValue = this.value;
        this.labelID = 'pc-input-' + Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 5)

        this.$watch('value', (val: string) => {
            this.inputValue = val;
        });
        this.$watch('inputValue', (val: string) => {
            this.$emit('input', val);
            this.$emit('change', val);
        });

        this.$on('focus', () => {
            const inputField: HTMLInputElement|HTMLTextAreaElement = this.$refs['input_field'] as HTMLInputElement;
            inputField.focus();
        });

        this.$on('blur', () => {
            const inputField: HTMLInputElement|HTMLTextAreaElement = this.$refs['input_field'] as HTMLInputElement;
            inputField.blur();
        });
    }

    clearValue() {
        this.inputValue = '';
    }
}
</script>

<style scoped lang="scss">
div.feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #878787;
}
</style>